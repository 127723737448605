/**
 * local icons
 */
require.context('./icons/', true, /\.svg$/);

/**
 * Bootstrap icons
 * https://icons.getbootstrap.com/
 */
// import 'bootstrap-icons/icons/gem.svg';
// import 'bootstrap-icons/icons/sun.svg';
// import 'bootstrap-icons/icons/moon.svg';

/**
 * Material UI icons
 * https://marella.me/material-design-icons/demo/svg/
 */
import '@material-design-icons/svg/filled/calendar_month.svg';
import '@material-design-icons/svg/filled/edit_calendar.svg';
import '@material-design-icons/svg/filled/person.svg';
import '@material-design-icons/svg/filled/menu.svg';
import '@material-design-icons/svg/filled/search.svg';
import '@material-design-icons/svg/filled/close.svg';
import '@material-design-icons/svg/filled/logout.svg';
import '@material-design-icons/svg/filled/login.svg';
import '@material-design-icons/svg/filled/language.svg';
import '@material-design-icons/svg/filled/location_on.svg';
import '@material-design-icons/svg/filled/headphones.svg';

/**
 * JS
 * Just include whatever you need
 */
import './js/header-search.js';
import './js/chocolat.js'; // lightbox
import './js/swiper.js'; // slider
import './js/bootstrap.js';
import './js/header-height.js';
import './js/lazy-image.js';
import './js/eventbrite-embed.js';
import './js/ads.js';
import './js/redirect-after-countdown.js';
