import Swiper, { Pagination, Thumbs, Mousewheel, FreeMode, Autoplay } from 'swiper';

Swiper.use([Pagination, Thumbs, Mousewheel, FreeMode, Autoplay]);

const swiperEls = document.querySelectorAll('.swiper');
const defaultSwiperConfig = {
  watchOverflow: true,
  mousewheel: {
    enabled: true,
    forceToAxis: true,
  },
};

let swiperThumbParents = {};

swiperEls.forEach((swiperEl) => {
  let swiperConfig = defaultSwiperConfig;

  if (swiperEl.hasAttribute('data-swiper-config')) {
    // add attribute "data-swiper-config" with config in json
    // format to .swiper to override swiper config per individual element
    const elementSwiperConfig = JSON.parse(swiperEl.getAttribute('data-swiper-config'));

    swiperConfig = {
      ...swiperConfig,
      ...elementSwiperConfig,
    };
  }

  if (swiperConfig.thumbsCode) {
    const thumbscode = swiperConfig.thumbsCode;
    const swiper = new Swiper(swiperEl, swiperConfig);
    swiperThumbParents[thumbscode] = swiper;
  } else {
    if (swiperConfig.getThumbs) {
      const thumbscode = swiperConfig.getThumbs;

      if (swiperThumbParents[thumbscode]) {
        swiperConfig = {
          ...swiperConfig,
          thumbs: {
            swiper: swiperThumbParents[thumbscode],
            autoScrollOffset: 0.2,
          },
        };
      }
    }
    new Swiper(swiperEl, swiperConfig);
  }
});
