const eventbriteEmbedAttr = 'data-js-eventbrite-embed';
const eventBriteEmbedEls = document.querySelectorAll(`[${eventbriteEmbedAttr}]`);

if ('EBWidgets' in window) {
  eventBriteEmbedEls.forEach((eventBriteEmbedEl) => {
    const eventId = parseInt(eventBriteEmbedEl.getAttribute(eventbriteEmbedAttr));

    window.EBWidgets.createWidget({
      widgetType: 'checkout',
      eventId: eventId,
      iframeContainerId: 'eventbrite-widget-container-' + eventId,
    });
  });
}
