const countEls = document.querySelectorAll('[data-after-count-redirect-to]');

countEls.forEach((countEl) => {
  const redirectUrl = countEl.getAttribute('data-after-count-redirect-to');
  let seconds = parseInt(countEl.innerHTML);
  if (seconds && redirectUrl.length > 0) {
    const countDown = () =>
      setTimeout(() => {
        if (seconds > 0) {
          seconds--;
          countEl.innerHTML = seconds;
          countDown();
        } else {
          window.location.href = redirectUrl;
        }
      }, 1000);

    countDown();
  }
});
