/**
 * This enhances the native html5 lazyload by adding
 * state representing classes to our picture.
 * This is coined to pimcores automatic image deferring.
 * _lazy-image.scss needed for this to work properly.
 * @author Thomas Franz <thomas.franz@krankikom.de>
 * @version 1.0.0
 */

if ('loading' in HTMLImageElement.prototype) {
  const lazyImgs = document.querySelectorAll('img[loading="lazy"], img[loading="eager"]');

  const classes = {
    waiting: 'lazy-image--waiting',
    error: 'lazy-image--error',
    errorShow: 'lazy-image--error-show',
  };

  for (const lazyImg of lazyImgs) {
    let lazyImgContainer = lazyImg.parentElement;
    if (lazyImgContainer.tagName === 'PICTURE') {
      lazyImgContainer.classList.add('lazy-image');

      const lazyImgLoad = () => {
        lazyImgContainer.classList.remove(classes.waiting);
      };

      const lazyImgError = () => {
        lazyImgContainer.classList.remove(classes.waiting);
        lazyImgContainer.classList.add(classes.error);
        setTimeout(function () {
          lazyImgContainer.classList.add(classes.errorShow);
        }, 100);
      };

      if (lazyImg) {
        if (!lazyImg.complete) {
          lazyImgContainer.classList.add(classes.waiting);
          lazyImg.addEventListener('load', lazyImgLoad, false);
          lazyImg.addEventListener('error', lazyImgError, false);
        }
      }
    }
  }
}
