const adsDataEl = document.querySelector('#ads-data');

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const skipAdbannerFix = 'skipadbannerfix' in params && params.skipadbannerfix === '1';
const skipSkyscraperFix = 'skipskyscraperfix' in params && params.skipskyscraperfix === '1';

const rem = (px) => px / 16 + 'rem';
const insertAfter = (newNode, referenceNode) => {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
};

if (!skipSkyscraperFix) {
  const adSkyscraperParentEl = document.querySelector('.ad-skyscraper__inner');

  if (adSkyscraperParentEl) {
    const skyscraperObserver = new MutationObserver((mutationList, skyscraperObserver) => {
      const adSkyscraperEl = adSkyscraperParentEl.querySelector('iframe');
      if (adSkyscraperEl) {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            const adWidth = adSkyscraperEl.getBoundingClientRect().width;
            const thresholt = 40;
            if (adWidth && adWidth > 0) {
              document.documentElement.style.setProperty(
                '--ad-skyscraper-width',
                rem(adWidth + thresholt)
              );
              skyscraperObserver.disconnect();
            }
          }
        }
      }
    });

    skyscraperObserver.observe(adSkyscraperParentEl, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }
} else {
  console.log('skipping skyscraper fix');
}
if (skipAdbannerFix) {
  document.body.classList.add('skip-adbanner-fix');
  console.log('skipping adbanner fix');
}
const mobileSlotMap = {
  banner: 'topmobile',
  banner2: 'topmobile2',
  banner3: 'topmobile3',
  teaser: 'mobile_teaser',
};

window.adsInitialized = false;
window.addEventListener(
  'CookiebotOnConsentReady',
  () => {
    if (window.Cookiebot.consent.marketing) {
      if (window.adsInitialized) {
        console.log('*** Ads already initialized, not doing it again!');
        return;
      }
      if (adsDataEl) {
        const slotsDataJson = adsDataEl.getAttribute('data-ad-slots');
        const keyValuesDataJson = adsDataEl.getAttribute('data-ad-keyValues');
        const tagsDataString = adsDataEl.getAttribute('data-ad-tags');
        const zoneDataString = adsDataEl.getAttribute('data-ad-zone');
        const SDG = window.SDG;

        const isMobileAd = window.matchMedia('only screen and (max-width: 760px)').matches;

        console.log('*** Starting Ads', isMobileAd);

        if (zoneDataString && zoneDataString.length > 0) {
          SDG.Publisher.setZone(zoneDataString);
        }

        if (tagsDataString && tagsDataString.length > 0) {
          SDG.Publisher.addKeywords(JSON.parse(tagsDataString));
        }

        if (keyValuesDataJson && keyValuesDataJson.length > 0) {
          const keyValues = JSON.parse(keyValuesDataJson);
          if (typeof keyValues === 'object') {
            Object.keys(keyValues).forEach((key) => {
              SDG.Publisher.addKeyValue(key, keyValues[key]);
            });
          }
        }

        if (slotsDataJson && slotsDataJson.length > 0) {
          const slots = JSON.parse(slotsDataJson);

          slots.forEach((slot) => {
            const adSlotEl = document.querySelector('#ad-' + slot);

            let mappedSlot = slot;
            if (isMobileAd && Object.prototype.hasOwnProperty.call(mobileSlotMap, slot)) {
              mappedSlot = mobileSlotMap[slot];
              console.log(
                '*** Mapping desktop slot',
                slot,
                'to mobile slot',
                mappedSlot,
                mobileSlotMap
              );
            }
            if (adSlotEl) {
              SDG.Publisher.registerSlot(mappedSlot, 'ad-' + slot);
            } else {
              console.log(`*** Slot not found: ${slot} in id ad-${slot}`);
              if (slot == 'teaser') {
                console.log('*** Trying to create teaser slot after second heading');
                const articleBody = document.querySelector('.blogpost-text');
                if (articleBody) {
                  const headings = articleBody.querySelectorAll('h1, h2, h3');
                  if (headings.length >= 2) {
                    const wrapperDivEl = document.createElement('div');
                    wrapperDivEl.innerHTML = '<div id="ad-teaser"></div>';
                    const adDivEl = wrapperDivEl.firstChild;
                    console.log('*** Adding teaser div', adDivEl, headings[1]);
                    //headings[1].prepend(adDivEl);
                    insertAfter(adDivEl, headings[1]);
                    SDG.Publisher.registerSlot(mappedSlot, 'ad-' + slot);
                  } else {
                    console.log('*** Article body div found, but not enough headings');
                  }
                }
              }
            }
          });

          SDG.Publisher.loadAllSlots();
          SDG.Publisher.finalizeSlots();
          window.adsInitialized = true;
        }
      }
    }
  },
  false
);
